var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật mẫu điểm danh",
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "updateAttendanceSample",
          attrs: {
            "label-width": "120px",
            model: _vm.updateAttendanceSample,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Loại điểm danh", prop: "attendanceType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "Chọn loại điểm danh", disabled: "" },
                  model: {
                    value: _vm.updateAttendanceSample.attendanceType,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.updateAttendanceSample,
                        "attendanceType",
                        $$v
                      )
                    },
                    expression: "updateAttendanceSample.attendanceType",
                  },
                },
                _vm._l(_vm.attendanceTypeList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nội dung", prop: "attendanceContent" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 10,
                  type: "textarea",
                  placeholder: "Nhập nội dung",
                },
                model: {
                  value: _vm.updateAttendanceSample.attendanceContent,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.updateAttendanceSample,
                      "attendanceContent",
                      $$v
                    )
                  },
                  expression: "updateAttendanceSample.attendanceContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("updateAttendanceSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("updateAttendanceSample")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }